import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";
import {
  getAvailableScales,
  //getScaleNotes,
  //getFingerings
} from "src/utils/scaleNoteLookup";
//import { PianoComponent } from "../../components/Piano/PianoComponent";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom'
import "./ScaleLibrary.css"
//import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea';
class ScaleLibraryContainer extends React.Component<Props, State> {
  componentDidMount() {}

  render() {
    let scales = getAvailableScales();
    //let last_tonic = "";
    
    let scale_html = (Object.entries(scales)).map((key_name, value) => {
      let u = key_name[0].replace(/ /g, "-");
      u = u.replace(/#/g, "-Sharp");

      //let [tonic, tonality] = key_name[0].split(" ");
      return (
        <Grid xs={12} sm={6} lg={3} item key={key_name[0]}>
          <Link to={"/library/"+u+"-Scale"}>
          <Card className="filterRoot" elevation={0}>
          <CardContent className="filterContent">
            
            <Typography gutterBottom variant="body1" component="h2">
            {key_name[0]}
          </Typography>
          
          
          
          </CardContent>
          </Card>
          </Link>
        </Grid>
      );
    })

    return (
      <div className="scaleLibraryRoot">
      <Grid
            container
            item
            className="scaleLibraryRoot"
            style={{ minHeight: "80vh" }}
            direction="row"

            spacing={2}
            justify="center"
            alignItems="center"
          >

          {scale_html}
          
      </Grid>
      </div>
    );
    
  }
 
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({  }, dispatch);
};

const mapStateToProps = (state: State) => {
  return {
    isGenerating: state.generator.isGenerating,
    key_signature: state.generator.key_signature,
    mode: state.generator.mode,
    tonality: state.generator.tonality,
    filters: state.generator.filters,
    remaining_scales: state.generator.remaining_scales,
    generated: state.generator.generated
  };
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ScaleLibraryContainer);
