import * as React from "react";
import { DisplayFilter } from "../../store/generator/types";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export interface FilterItemComponentProps {
    id: number;
  item: DisplayFilter;
  type: string;
  update: Function;
}

export class FilterItemComponent extends React.Component<
FilterItemComponentProps,
  {}
> {
  render() {
    //console.log(this.props.item.enabled);
    return (
      <FormControlLabel
          value={this.props.item.name}
          control={<Checkbox id={this.props.item.name} color="default" name={this.props.type} checked={this.props.item.enabled} onChange={(e) => {this.props.update(this.props.id, e.target.checked)}} />}
          label={this.props.item.display}
          labelPlacement="end"
        />
         
    );
  }
}
