import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./pages/App/App";
import Home from "./pages/Home/Home";
import "./index.css";
//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from './registerServiceWorker';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store";
import thinkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom'
import ScaleLibrary from './pages/App/ScaleLibrary';
import Scale from './pages/App/Scale';

const loggerMiddleware = createLogger();

export const createReduxStore = () =>
  createStore(rootReducer, applyMiddleware(thinkMiddleware, loggerMiddleware));

ReactDOM.render(
  <Provider store={createReduxStore()}>
    <BrowserRouter>
    <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/app' component={App}/>
        <Route path='/library/:scale' component={Scale}/>
        <Route path='/library' component={ScaleLibrary}/>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);
unregister();
