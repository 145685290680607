import { generateReducer } from "./generator/reducer";
import { GenerateState } from "./generator/types";

import { combineReducers } from "redux";
import { ExampleState } from './example/types';
import { exampleReducer } from './example/reducer';

export interface State {
  generator: GenerateState;
  example: ExampleState;
}

export default combineReducers<State>({ generator: generateReducer, example: exampleReducer });
