import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";
import { KeySignatureComponent } from "../../components/Generator/KeySignatureComponent";
//import { FilterComponent } from "src/components/Generator/FilterComponent";
import { PianoComponent } from "../../components/Piano/PianoComponent";
//import { Filter } from "../../store/generator/types";
import { set_hand } from "../../store/generator/thunk";
import {
  getScaleNotes,
  getScaleDescendingNotes,
  getFingerings,
} from "src/utils/scaleNoteLookup";
import Grid from "@material-ui/core/Grid";
//import Button from "@material-ui/core/Button";
import "./App.css";
import HeaderContainer from "src/containers/Header/HeaderContainer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
//import GeneratorContainer from 'src/containers/Generator/GeneratorContainer';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from 'react-router-dom'

import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

class ScaleLibrary extends React.Component<Props & { match: any }, State> {
  render() {
    console.log(this.props.match.params.scale);
    var scale = this.props.match.params.scale.replace(/-/g, " ");

    scale = scale.replace(/ Sharp/g, "#");
    scale = scale.replace(/ Scale/g, "");

    let [key_signature, ...mode] = scale.split(" ");
    mode = mode.join(" ");
    console.log(scale);
    console.log(mode);
    console.log(key_signature);

    var notes_in_scale = getScaleNotes(scale);

    var normalised_scale = scale.replace(" Melodic", "").replace(" Harmonic", "");
    var normalised_notes_in_scale = getScaleNotes(normalised_scale);

    var major_degrees = [
      "Tonic",
      "Supertonic",
      "Mediant",
      "Subdominant",
      "Dominant",
      "Submediant",
      "Leading Tone",
    ];

    var minor_degrees = [
      "Tonic",
      "Supertonic",
      "Mediant",
      "Subdominant",
      "Dominant",
      "Submediant",
      "Subtonic",
    ];

    var degrees: Array<String> = [];

    if(scale.toUpperCase().includes("MAJOR")) {
      degrees = major_degrees;
    } else {
      degrees = minor_degrees;
    }

    let relative = "";
    let relative_link = "";

    let scale_degrees = notes_in_scale.map((val: String, i) => {
      let s: string =
        val.charAt(0).toUpperCase() +
        val
          .slice(1)
          .replace("s", "#")
          .replace("z", "bb");

          /*if(scale.toUpperCase().includes("MAJOR") && (i == 5)) {
            relative = s;
          } else if(scale.toUpperCase().includes("MINOR") && (i == 2)) {
            relative = s;
          }*/
      if (i <= 6) {
        return (
          <Typography variant={"body1"}>
            {s} - {degrees[i]}
          </Typography>
        );
      }

      return;
    });

    function enharmonise(val: string) {
      return val.replace(/Bbb/g, "A")
      .replace(/Dbb/g, "C")
      .replace(/Ebb/g, "D")
      .replace(/Fbb/g, "D#")
      .replace(/Gbb/g, "F")
      .replace(/Abb/g, "G")
      .replace(/Cbb/g, "A#")
      .replace(/Fb/g, "E")
    }
    normalised_notes_in_scale.map((val: String, i) => {
      let s: string =
        val.charAt(0).toUpperCase() +
        val
          .slice(1)
          .replace("s", "#")
          .replace("z", "bb");

          if(scale.toUpperCase().includes("MAJOR") && (i == 5)) {
            relative = s;
            relative_link = enharmonise(s).replace(/A#/g, 'Bb').replace(/#/g, "-Sharp")+"-Minor";
          } else if(scale.toUpperCase().includes("MINOR") && (i == 2)) {
            relative = s;

            relative_link = enharmonise(s).replace(/A#/g, 'Bb').replace(/#/g, "-Sharp")+"-Major";
          }
      
      return;
    });

    let fingering = ["1 - Thumb", "2 - Index Finger", "3 - Middle Finger", "4 - Ring Finger", "5 - Little Finger"].map(val => {
      return (
        <Typography variant={"body1"}>
            {val}
          </Typography>
      );
    })

    let major_intervals = ["1", "2", "3", "4", "5", "6", "7"];
    let minor_intervals = ["1", "2", "b3", "4", "5", "b6", "b7"];
    let harmonic_intervals = ["1", "2", "b3", "4", "5", "b6", "7"];
    let melodic_intervals = ["1", "2", "b3", "4", "5", "6", "7"];

    let the_intervals: Array<String> = [];

    if(scale.toUpperCase().includes("MAJOR")) {
      the_intervals = major_intervals;
    } else if(scale.toUpperCase().includes("HARMONIC")) {
      the_intervals = harmonic_intervals;
    } else if(scale.toUpperCase().includes("MELODIC")) {
      the_intervals = melodic_intervals;
    } else if(scale.toUpperCase().includes("MINOR")) {
      the_intervals = minor_intervals;
    }

    let interval_html = the_intervals.map(val => {
      return (
        <Typography variant={"body1"}>
            {val}
          </Typography>
      );
    })

    /*let relative_keys = {
      "C": "A",
      "C#": "A#",
      "Db": "Bb",
      "D": "B",
      "Eb": "C",
      "E": "C#",
      "F": "D",
      "F#": "D#",
      "Gb": "Eb",
      "G": "E",
      "Ab": "F",
      "Fb": "Db",
      "A": "F#",
      "Bb": "G",
      "B": "G#"
    };*/

    


    return (
      <Container>
        <CssBaseline />
        <HeaderContainer renderCTA={true} />
        <Grid
          container
          item
          className="generatorFilterRoot"
          style={{ minHeight: "80vh" }}
          direction="row"
          spacing={1}
          justify="center"
          alignItems="center"
        >
          <Grid
            container
            className="generatorFilterRoot"
            direction="column"
            xs={12}
            spacing={2}
            justify="center"
            alignItems="center"
          >
            <Grid xs={12} sm={12} spacing={0}  item>
                
                </Grid>
            <Grid xs={12} lg={12} item>
              <KeySignatureComponent
                key_signature={scale}
                mode={mode}
                tonality={mode}
              />
            </Grid>
            {getFingerings(scale)[this.props.hand].length > 1 && (
              <Grid xs={12} lg={12} item>
                <RadioGroup
                  color="default"
                  aria-label="position"
                  name="position"
                  value={this.props.hand}
                  onChange={(event) => {
                    this.props.set_hand(parseInt(event.target.value));
                  }}
                  row
                >
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio color="default" style={{ color: "#fff" }} />
                    }
                    label="Left"
                    labelPlacement="bottom"
                    color="default"
                    style={{ color: "#fff" }}
                  />
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio color="default" style={{ color: "#fff" }} />
                    }
                    label="Right"
                    labelPlacement="bottom"
                    color="default"
                    style={{ color: "#fff" }}
                  />
                </RadioGroup>
              </Grid>
            )}
            {getFingerings(scale)[this.props.hand].length < 1 && (
              <Grid xs={12} lg={12} item>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ color: "#fff" }}
                >
                  No example available
                </Typography>
              </Grid>
            )}
            {scale.includes("Melodic") && (
              <Grid xs={12} lg={12} item>
                <Typography
                  align="center"
                  variant="h6"
                  style={{ color: "#fff" }}
                >
                  Ascending
                </Typography>
              </Grid>
            )}

            <Grid xs={12} lg={12} item>
              <PianoComponent
                notes={getScaleNotes(scale)}
                fingering={getFingerings(scale)[this.props.hand]}
                hand={this.props.hand}
                descending={false}
              />
            </Grid>
            {scale.includes("Melodic") && (
              <div>
                <Grid xs={12} lg={12} item>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ color: "#fff" }}
                  >
                    Descending
                  </Typography>
                </Grid>
                <Grid xs={12} lg={12} item>
                  <PianoComponent
                    notes={getScaleDescendingNotes(scale)}
                    fingering={getFingerings(scale)[this.props.hand]}
                    hand={this.props.hand}
                    descending={true}
                  />
                </Grid>
              </div>
            )}
            <Grid xs={12} direction={"row"} spacing={6} justify={"center"} container item>
            <Grid xs={12} sm={12} spacing={0}  item>
                
              </Grid>
              
              
              <Grid xs={12} md={3} spacing={3} item>
              <Link to={"/library/"+relative_link}>
                <Card className="filterRoot" elevation={0} >
                  <CardContent className="filterContent">
                    <Typography gutterBottom variant="h6" component="h2">
                      <strong>
                        { scale.toUpperCase().includes("MAJOR") && ("Relative Minor") }
                        { scale.toUpperCase().includes("MINOR") && ("Relative Major") }
                      </strong>
                    </Typography>
                    <Typography gutterBottom variant="body1" component="h2">
                     {relative}
                    </Typography>
                  </CardContent>
                </Card>

              </Link>
              </Grid>

              <Grid xs={12} md={3} spacing={3}  item>
                <Card className="filterRoot" elevation={0} style={{ width: "100%", height: "100%" }}>
                  <CardContent className="filterContent">
                    <Typography gutterBottom variant="h6" component="h2">
                      <strong>Scale Degrees</strong>
                    </Typography>
                    <Typography gutterBottom variant="body1" component="h2">
                      {scale_degrees}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} md={3} spacing={3}  item>
                <Card className="filterRoot" elevation={0} style={{ width: "100%", height: "100%" }}>
                  <CardContent className="filterContent">
                    <Typography gutterBottom variant="h6" component="h2">
                      <strong>Fingering</strong>
                    </Typography>
                    <Typography gutterBottom variant="body1" component="h2">
                     {fingering}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} md={3} spacing={3}  item>
                <Card className="filterRoot" elevation={0} style={{ width: "100%", height: "100%" }}>
                  <CardContent className="filterContent">
                    <Typography gutterBottom variant="h6" component="h2">
                      <strong>Intervals</strong>
                    </Typography>
                    <Typography gutterBottom variant="body1" component="h2">
                     {interval_html}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ set_hand }, dispatch);
};

const mapStateToProps = (state: State) => {
  return {
    hand: state.generator.hand,
  };
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ScaleLibrary);
