import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";
import { generate, next, update_filter, set_hand } from "../../store/generator/thunk";
import { KeySignatureComponent } from "../../components/Generator/KeySignatureComponent";
import { FilterComponent } from "src/components/Generator/FilterComponent";
import { PianoComponent } from "../../components/Piano/PianoComponent";
import { Filter } from "../../store/generator/types";
import {
  getScaleNotes,
  getScaleDescendingNotes,
  getFingerings
} from "src/utils/scaleNoteLookup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import "./Generator.css";
import { Typography, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

class GeneratorContainer extends React.Component<Props, State> {
  componentDidMount() {}

  construct() {
    
  }

  render() {
    return (
      <div className="generatorRoot">
        {!this.props.isGenerating && this.props.key_signature == undefined && (
          <Grid
            container
            item
            className="generatorFilterRoot"
            style={{ minHeight: "80vh" }}
            direction="row"
            
            spacing={1}
            justify="center"
            alignItems="center"
          >
            <Grid xs={12} lg={12} item>
              <FilterComponent
                filter={Object.assign({}, this.props.filters)}
                handle_update={(f: Filter) => {
                  this.props.update_filter(f);
                }}
              />
            </Grid>
            <Grid xs={6} lg={4} item>
              <Button
                id="shuffle-scales-button"
                style={{ fontWeight: 700, fontSize: "1.8em" }}
                className="example_c"
                fullWidth={true}
                size={"large"}
                onClick={() => {
                  this.props.generate(this.props.filters);
                  this.props.next(this.props.remaining_scales, false);
                }}
              >
                Shuffle
              </Button>
            </Grid>
          </Grid>
        )}
        {/* ---------------------------------------- */}
        {this.props.isGenerating && (
          <Grid
            container
            item
            className="generatorFilterRoot"
            style={{ minHeight: "80vh" }}
            direction="row"
            xs={12}
            spacing={1}
            justify="center"
            alignItems="center"
          >
            <Grid xs={12} lg={12} item>
              <Typography align="center" variant="h5" style={{ color: "#fff" }}>
                Shaking the box...
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* ---------------------------------------- */}
        {!this.props.isGenerating &&
          this.props.generated &&
          this.props.key_signature != undefined && (
            <Grid
              container
              className="generatorFilterRoot"
              direction="column"
              
              spacing={1}
              justify="center"
              alignItems="center"
            >
              <Grid
                direction="row"
                container
                xs={12}
                lg={12}
                item
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6} lg={3}>
                  <Button
                    className="example_c"
                    fullWidth={true}
                    size={"large"}
                    onClick={() => {
                      this.props.next(this.props.remaining_scales, true);
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Button
                    className="example_c"
                    fullWidth={true}
                    size={"large"}
                    onClick={() => {
                      this.props.next(this.props.remaining_scales, false);
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={12} lg={12} item>
                <KeySignatureComponent
                  key_signature={this.props.key_signature}
                  mode={this.props.mode}
                  tonality={this.props.tonality}
                />
              </Grid>
              {getFingerings(this.props.key_signature)[this.props.hand].length > 1 &&
              <Grid xs={12} lg={12} item>
              <RadioGroup color="default"  aria-label="position" name="position" value={this.props.hand} onChange={(event) => { this.props.set_hand(parseInt(event.target.value)); }} row>
                <FormControlLabel
                  value={0}
                  control={<Radio color="default" style={{color: "#fff"}} />}
                  label="Left"
                  labelPlacement="bottom"
                  color="default" style={{color: "#fff"}} 
                />
                <FormControlLabel
                value={1}
                control={<Radio color="default" style={{color: "#fff"}}  />}
                label="Right"
                labelPlacement="bottom"
                color="default" style={{color: "#fff"}} 
              />
              </RadioGroup>
              
                </Grid>
              }
              {getFingerings(this.props.key_signature)[this.props.hand].length < 1 &&
              <Grid xs={12} lg={12} item>
              <Typography
                align="center"
                variant="h6"
                style={{ color: "#fff" }}
              >
                No example available
              </Typography>
            </Grid>
              }
              {this.props.key_signature.includes("Melodic") && (
                <Grid xs={12} lg={12} item>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ color: "#fff" }}
                  >
                    Ascending
                  </Typography>
                </Grid>
              )}
              
              <Grid xs={12} lg={12} item>
                <PianoComponent
                  notes={getScaleNotes(this.props.key_signature)}
                  fingering={getFingerings(this.props.key_signature)[this.props.hand]}
                  hand={this.props.hand}
                  descending={false}
                />
              </Grid>
              {this.props.key_signature.includes("Melodic") && (
                <div>
                  <Grid xs={12} lg={12} item>
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ color: "#fff" }}
                    >
                      Descending
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <PianoComponent
                      notes={getScaleDescendingNotes(this.props.key_signature)}
                      fingering={getFingerings(this.props.key_signature)[this.props.hand]}
                      hand={this.props.hand}
                      descending={true}
                    />
                  </Grid>
                </div>
              )}
              
            </Grid>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ generate, next, update_filter, set_hand }, dispatch);
};

const mapStateToProps = (state: State) => {
  return {
    isGenerating: state.generator.isGenerating,
    key_signature: state.generator.key_signature,
    mode: state.generator.mode,
    tonality: state.generator.tonality,
    filters: state.generator.filters,
    remaining_scales: state.generator.remaining_scales,
    generated: state.generator.generated,
    hand: state.generator.hand
  };
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(GeneratorContainer);
