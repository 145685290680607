import { Action } from "../common/types";
import { GenerateActions, Filter } from "./types";

export const generateRequest = (): Action => ({
  type: GenerateActions.GENERATE_REQUEST
});

export const generateSuccess = (remaining_scales: Array<String>): Action => ({
  type: GenerateActions.GENERATE_SUCCESS,
  payload: {
    remaining_scales
  }
});

export const generateFailure = (): Action => ({
  type: GenerateActions.GENERATE_FAILURE,
  error: true
});


export const nextSuccess = (key_signature?: String): Action => ({
    type: GenerateActions.NEXT_SUCCESS,
    payload: {
        key_signature
    }
})

export const updateFilterSuccess = (filters: Filter): Action => ({
    type: GenerateActions.UPDATE_FILTER_SUCCESS,
    payload: {
        filters
    }
});

export const setHandSuccess = (hand: number): Action => ({
  type: GenerateActions.SET_HAND_SUCCESS,
  payload: {
    hand
  }
})