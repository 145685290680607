import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";
import GeneratorContainer from 'src/containers/Generator/GeneratorContainer';
import './App.css';
import HeaderContainer from 'src/containers/Header/HeaderContainer';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
//import {PianoComponent} from '../../components/Piano/PianoComponent';

class App extends React.Component<Props, State> {

  render() {
    return (
      <Container>
        <CssBaseline />
        <HeaderContainer renderCTA={false} />
        <GeneratorContainer />
      </Container>
      
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state: State) => {
  return {};
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(App);
