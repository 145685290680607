import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";

import './App.css';
import HeaderContainer from 'src/containers/Header/HeaderContainer';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid, Typography, Button/*, TextField*/ } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import Shuffle from '@material-ui/icons/Shuffle';
import MusicNote from '@material-ui/icons/MusicNote';
//import {PianoComponent} from '../../components/Piano/PianoComponent';

class Home extends React.Component<Props, State> {

  render() {
    return (
      <Container>
        <CssBaseline />
        <HeaderContainer renderCTA={false} />
        <Grid
        xs={12}
        lg={12}
        container
        direction={"row"}
        justify="center"
        alignItems="center"
        style={{color: "#fff", textAlign: "center"}}
        >
            <Grid spacing={10} direction={"row"} justify="center" alignItems="center" container item style={{color: "#fff", textAlign: "center", height: "100vh"}}>
                <Grid xs={12} lg={12} item>
                    <Grid xs={12} item>
                        <Typography variant="h1" className={"cta-text"}>
                            <strong>box</strong>of<strong>scales</strong>
                        </Typography>
                    </Grid>
                    <Grid xs={12} lg={12} item>
                        <Typography variant="h4" className={"cta-sub-text"}>
                        Online daily scales training aid.
                        </Typography>
                    </Grid>
                    
                </Grid>
                <Grid xs={12} sm={4} item>
                        <Button id="homepage-open-app-button" className={"example_c"} fullWidth={true}
                    size={"large"} href="/app" target="" color="inherit" style={{fontSize: "2em"}}>Open</Button>
                    </Grid>
                    <Grid spacing={3} direction={"row"} justify="center" alignItems="center" container item style={{color: "#fff", textAlign: "center", height: "30vh"}}>
                <Grid spacing={1} sm={4} direction={"column"} justify="center" alignItems="center" container item>
                    <Grid item >
                        <Typography variant="h4">
                            <CheckBox style={{fontSize: "2em"}} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">
                        Pick
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                        Scales you want to practise.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid spacing={1} sm={4} direction={"column"}  justify="center" alignItems="center" container item>
                    <Grid item>
                        <Typography variant="h4">
                            <Shuffle style={{fontSize: "2em"}} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">
                        Shuffle
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                        Shake the box to mix the scales up.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid spacing={1} sm={4} direction={"column"}  justify="center" alignItems="center" container item>
                    <Grid item>
                        <Typography variant="h4">
                            <MusicNote style={{fontSize: "2em"}} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">
                        Play
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                        See notes, finger patterns, and audio.
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
            </Grid>
            
            
        </Grid>
      </Container>
      
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state: State) => {
  return {};
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(Home);
