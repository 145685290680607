import * as React from "react";
import { returntypeof } from "react-redux-typescript";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { State } from "../../store";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
//import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Twitter from '@material-ui/icons/Twitter';
//import FreeBreakfast from '@material-ui/icons/FreeBreakfast';
import { Link } from 'react-router-dom'
import "./Header.css";

class HeaderContainer extends React.Component<Props & {renderCTA: boolean}, State> {
  componentDidMount() {

  }

  showWhatIsNew() {

  }

  render() {
    return (
      <div>
          <div className={"headerRoot"} style={{color: "#fff", backgroundColor: "transparent", margin: 0, padding: 0}} >
            <AppBar position="static" style={{color: "#fff", backgroundColor: "transparent", margin: 0, padding: 0}} elevation={0}>
            <Toolbar className="headerToolbar" variant="regular" style={{color: "#fff", backgroundColor: "transparent", margin: 0, padding: 0}}>
              {/*<IconButton edge="start"  color="inherit" aria-label="menu">
                <MenuIcon />
    </IconButton>*/}
              <Typography variant="h6" className={"headerTitle"}>
                <Link to='/'><span className="logo"><b>box</b>of<b>scales</b></span></Link>
              </Typography>
              <Button className={"headerItem"} href="/library" target="" color="inherit">Library</Button>
              {this.props.renderCTA &&
              <Button className={"headerItemCTA"} href="/app" target="" color="inherit">Open</Button>
  }
              <Button className={"headerItem"} href="https://twitter.com/boxofscales" target="_blank" color="inherit"><Twitter /></Button>
              {/*<Button className={"headerItem"} href="https://ko-fi.com/jacobbaker" target="_blank" color="inherit"><FreeBreakfast /></Button>*/}
            </Toolbar>
          </AppBar>
        </div>
      
        </div>
        
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({  }, dispatch);
};

const mapStateToProps = (state: State) => {
  return {  };
};

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
