/**
 * Double flat is now z instead of bb
 * @param name 
 */

 export const getScaleDescendingNotes = (name: string) => {
     if(name.includes("Melodic")) {
         name=name.replace("Melodic ", "");
     }

     return getScaleNotes(name);

     /*const scaleNoteLookup: {} = {
         "C Melodic Minor": ["c", "bb", "ab", "g", "f", "eb", "d", "c"],
         "C# Melodic Minor": [],
         "Db Melodic Minor": [],
         "D Melodic Minor": [],
         "Eb Melodic Minor": [],
         "E Melodic Minor": [],
         "F Melodic Minor": [],
         "F# Melodic Minor": [],
         "Gb Melodic Minor": [],
         "G Melodic Minor": [],
         "Ab Melodic Minor": [],
         "A Melodic Minor": [],
         "Bb Melodic Minor": [],
         "B Melodic Minor": []
     }*/
 }

export const getFingerings = (name: string) => {
    const leftHandFingerings: {} = {
        1: [5, 4, 3, 2, 1, 3, 2, 1],
        2: [3, 2, 1, 4, 3, 2, 1, 3],
        3: [4, 3, 2, 1, 4, 3, 2, 1],
        4: [3, 2, 1, 3, 2, 1, 3, 2],
        5: [3, 2, 1, 3, 2, 1, 4, 3],
        6: [2, 1, 2, 3, 1, 2, 3, 4],
        7: [2, 1, 3, 2, 1, 4, 3, 2],
        8: [3, 1, 2, 3, 4, 1, 2, 3],
        9: [2, 1, 4, 3, 2, 1, 3, 2],
        10: [4, 3, 2, 1, 3, 2, 1, 4],
        11: [3, 2, 1, 4, 3, 2, 1, 3]
    }

    const rightHandFingerings: {} = {
        1: [1, 2, 3, 1, 2, 3, 4, 5],
        2: [2, 3, 1, 2, 3, 4, 1, 2],
        3: [3, 1, 2, 3, 4, 1, 2, 3],
        4: [1, 2, 3, 4, 1, 2, 3, 4],
        5: [2, 3, 4, 1, 2, 3, 1, 2],
        6: [2, 3, 1, 2, 3, 1, 2, 3],
        7: [3, 4, 1, 2, 3, 1, 2, 3],
        8: [4, 1, 2, 3, 1, 2, 3, 4],
        9: [2, 1, 2, 3, 1, 2, 3, 4]
    }


    /**
     * 0 - left
     * 1 - right
     */
    const fingeringLookup: {} = {
        "C Major": [1, 1],
        "C Minor": [1, 1],
        "C Harmonic Minor": [1, 1],
        "C Melodic Minor": [1, 1],
        "C# Major": [2, 2],
        "C# Minor": [2, 2],
        "C# Harmonic Minor": [2, 2],
        "C# Melodic Minor": [2, 2],
        "Db Major": [2, 2],
        "Db Minor": [2, 2],
        "Db Harmonic Minor": [2, 2],
        "Db Melodic Minor": [2, 2],
        "D Major": [1, 1],
        "D Minor": [1, 1],
        "D Harmonic Minor": [1, 1],
        "D Melodic Minor": [1, 1],
        "Eb Major": [3, 2],
        "Eb Minor": [3, 2],
        "Eb Harmonic Minor": [3, 2],
        "Eb Melodic Minor": [9, 3],
        "E Major": [1, 1],
        "E Minor": [1, 1],
        "E Harmonic Minor": [1, 1],
        "E Melodic Minor": [1, 1],
        "F Major": [1, 4],
        "F Minor": [1, 4],
        "F Harmonic Minor": [1, 4],
        "F Melodic Minor": [1, 4],
        "F# Major": [3, 5],
        "F# Minor": [3, 6],
        "F# Harmonic Minor": [3, 6],
        "F# Melodic Minor": [10, 2],
        "Gb Major": [3, 5],
        "Gb Minor": [3, 6],
        "Gb Harmonic Minor": [3, 6],
        "Gb Melodic Minor": [10, 2],
        "G Major": [1, 1],
        "G Minor": [1, 1],
        "G Harmonic Minor": [1, 1],
        "G Melodic Minor": [1, 1],
        "Ab Major": [2, 7],
        "Ab Minor": [4, 7],
        "Ab Harmonic Minor": [5, 6],
        "Ab Melodic Minor": [11, 7],
        "A Major": [1, 1],
        "A Minor": [1, 1],
        "A Harmonic Minor": [1, 1],
        "A Melodic Minor": [1, 1],
        "Bb Major": [2, 8],
        "Bb Minor": [6, 9],
        "Bb Harmonic Minor": [7, 9],
        "Bb Melodic Minor": [9, 8],
        "B Major": [3, 1],
        "B Minor": [3, 1],
        "B Harmonic Minor": [3, 1],
        "B Melodic Minor": [3, 1],
    }

    var idx = fingeringLookup[name];

    if(idx !== undefined) {
        return [leftHandFingerings[idx[0]], rightHandFingerings[idx[1]]];
    } else {
        return [[],[]];
    }
}



export const getScaleNotes = (name: string) => {
    
    const scaleNoteLookup: {} = getAvailableScales();
    
    var scale: [] = [];

    try {
       scale = scaleNoteLookup[name]

    } catch(Exception) {
        console.log("Undefined scale!!!! "+name);
    }

    return scale;
}

export const getAvailableScales = () => {
    const scaleNoteLookup: {} = {
        "C Major": ["c", "d", "e", "f", "g", "a", "b", "c"],
        "C Minor": ["c", "d", "eb", "f", "g", "ab", "bb", "c"],
        "C Harmonic Minor": ["c", "d", "eb", "f", "g", "ab", "b", "c"],
        "C Melodic Minor": ["c", "d", "eb", "f", "g", "a", "b", "c"],
        "C# Major": ["cs", "ds", "es", "fs", "gs", "as", "bs", "cs"],
        "C# Minor": ["cs", "ds", "e", "fs", "gs", "a", "b", "cs"],
        "C# Harmonic Minor": ["cs", "ds", "e", "fs", "gs", "a", "bs", "cs"],
        "C# Melodic Minor": ["cs", "ds", "e", "fs", "gs", "as", "bs", "cs"],
        "Db Major": ["db", "eb", "f", "gb", "ab", "bb", "c", "db"],
        "Db Minor": ["db", "eb", "fb", "gb", "ab", "bz", "cb", "db"],
        "Db Harmonic Minor": ["db", "eb", "fb", "gb", "ab", "bz", "c", "db"],
        "Db Melodic Minor": ["db", "eb", "fb", "gb", "ab", "bb", "c", "db"],
        "D Major": ["d", "e", "fs", "g", "a", "b", "cs", "d"],
        "D Minor": ["d", "e", "f", "g", "a", "bb", "c", "d"],
        "D Harmonic Minor": ["d", "e", "f", "g", "a", "bb", "cs", "d"],
        "D Melodic Minor": ["d", "e", "f", "g", "a", "b", "cs", "d"],
        "Eb Major": ["eb", "f", "g", "ab", "bb", "c", "d", "eb"],
        "Eb Minor": ["eb", "f", "gb", "ab", "bb", "cb", "db", "eb"],
        "Eb Harmonic Minor": ["eb", "f", "gb", "ab", "bb", "cb", "d", "eb"],
        "Eb Melodic Minor": ["eb", "f", "gb", "ab", "bb", "c", "d", "eb"],
        "E Major": ["e", "fs", "gs", "a", "b", "cs", "ds", "e"],
        "E Minor": ["e", "fs", "g", "a", "b", "c", "d", "e"],
        "E Harmonic Minor": ["e", "fs", "g", "a", "b", "c", "ds", "e"],
        "E Melodic Minor": ["e", "fs", "g", "a", "b", "cs", "ds", "e"],
        "F Major": ["f", "g", "a", "bb", "c", "d", "e", "f"],
        "F Minor": ["f", "g", "ab", "bb", "c", "db", "eb", "f"],
        "F Harmonic Minor": ["f", "g", "ab", "bb", "c", "db", "e", "f"],
        "F Melodic Minor": ["f", "g", "ab", "bb", "c", "d", "e", "f"],
        "F# Major": ["fs", "gs", "as", "b", "cs", "ds", "es", "fs"],
        "F# Minor": ["fs", "gs", "a", "b", "cs", "d", "e", "fs"],
        "F# Harmonic Minor": ["fs", "gs", "a", "b", "cs", "d", "es", "fs"],
        "F# Melodic Minor": ["fs", "gs", "a", "b", "cs", "ds", "es", "fs"],
        "Gb Major": ["gb", "ab", "bb", "cb", "db", "eb", "f", "gb"],
        "Gb Minor": ["gb", "ab", "bz", "cb", "db", "ez", "fb", "gb"],
        "Gb Harmonic Minor": ["gb", "ab", "bz", "cb", "db", "ez", "f", "gb"],
        "Gb Melodic Minor": ["gb", "ab", "bz", "cb", "db", "eb", "f", "gb"],
        "G Major": ["g", "a", "b", "c", "d", "e", "fs", "g"],
        "G Minor": ["g", "a", "bb", "c", "d", "eb", "f", "g"],
        "G Harmonic Minor": ["g", "a", "bb", "c", "d", "eb", "fs", "g"],
        "G Melodic Minor": ["g", "a", "bb", "c", "d", "e", "fs", "g"],
        "Ab Major": ["ab", "bb", "c", "db", "eb", "f", "g", "ab"],
        "Ab Minor": ["ab", "bb", "cb", "db", "eb", "fb", "gb", "ab"],
        "Ab Harmonic Minor": ["ab", "bb", "cb", "db", "eb", "fb", "g", "ab"],
        "Ab Melodic Minor": ["ab", "bb", "cb", "db", "eb", "f", "g", "ab"],
        "A Major": ["a", "b", "cs", "d", "e", "fs", "gs", "a"],
        "A Minor": ["a", "b", "c", "d", "e", "f", "g", "a"],
        "A Harmonic Minor": ["a", "b", "c", "d", "e", "f", "gs", "a"],
        "A Melodic Minor": ["a", "b", "c", "d", "e", "fs", "gs", "a"],
        "Bb Major": ["bb", "c", "d", "eb", "f", "g", "a", "bb"],
        "Bb Minor": ["bb", "c", "db", "eb", "f", "gb", "ab", "bb"],
        "Bb Harmonic Minor": ["bb", "c", "db", "eb", "f", "gb", "a", "bb"],
        "Bb Melodic Minor": ["bb", "c", "db", "eb", "f", "g", "a", "bb"],
        "B Major": ["b", "cs", "ds", "e", "fs", "gs", "as", "b"],
        "B Minor": ["b", "cs", "d", "e", "fs", "g", "a", "b"],
        "B Harmonic Minor": ["b", "cs", "d", "e", "fs", "g", "as", "b"],
        "B Melodic Minor": ["b", "cs", "d", "e", "fs", "gs", "as", "b"],
    }

    return scaleNoteLookup;
}