import * as React from "react";
import Typography from '@material-ui/core/Typography';
export interface KeySignatureComponentProps {
    key_signature: string,
    mode?: string,
    tonality?: string,
}

export class KeySignatureComponent extends React.Component<KeySignatureComponentProps, {}> 
{
  render() {
      //var display = this.props.key_signature + " " + (this.props.tonality == "" ? this.props.mode : this.props.tonality);
    return (
        <Typography align="center" variant="h3" style={{color: "#fff"}}>
          {this.props.key_signature}
        </Typography>
        
    );
  }
}
