export interface Filter {
    modes: Array<DisplayFilter>;
    keys: Array<Key>;
    tonality: Array<DisplayFilter>;
    allow_enharmonic: boolean;
    randomisation: Array<DisplayFilter>;
    presets: Array<DisplayFilter>;
}
  
  export interface ModeFilter {
    ionian: boolean,
    dorian: boolean,
    phyrigian: boolean,
    lydian: boolean,
    mixolyian: boolean,
    aoelian: boolean,
    locrian: boolean
  }

  export interface Key {
      name: string
      display: string
      enabled: boolean
  }

  export interface DisplayFilter {
      name: string,
      display: string,
      enabled: boolean
  }

  export enum GenerateActions {
    GENERATE_REQUEST = "@@example/GENERATE_REQUEST",
    GENERATE_SUCCESS = "@@example/GENERATE_SUCCESS",
    GENERATE_FAILURE = "@@example/GENERATE_FAILURE",
    NEXT_SUCCESS = "@@example/NEXT_SUCCESS",
    UPDATE_FILTER_SUCCESS = "@@example/UPDATE_FILTER_SUCCESS",
    SET_HAND_SUCCESS = "@@example/SET_HAND_SUCCESS"
  }
  
  export interface GenerateState {
    readonly filter_updated: boolean;
    readonly isGenerating: boolean;
    readonly key_signature?: string;
    readonly mode?: string;
    readonly tonality?: string;
    readonly generated: boolean;
    readonly remaining_scales: Array<String>;
    readonly filters: Filter;
    readonly hand: number;
  }
  