import {
    generateRequest,
    generateSuccess,
    nextSuccess,
    updateFilterSuccess,
    setHandSuccess
  } from "./actions";
  
  import { Filter } from "./types";


  /*function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}*/

/**
 * Fisher-Yates shuffle (modernised)
 * Shuffles array in place. ES6 version
 * @param {Array} arrParam items An array containing the items.
 */
function shuffle(arrParam: any[]): any[]{
    let arr = arrParam.slice(),
        length = arr.length,
        temp,
        i;

    while(length){
        i = Math.floor(Math.random() * length--);

        temp = arr[length];
        arr[length] = arr[i];
        arr[i] = temp;
    }

    return arr;
}

  export const generate = (filter: Filter) => (dispatch: Function) => {
      console.log("in generate!");
      console.log(filter);
    dispatch(generateRequest());
  
    setTimeout(() => {
        var key_selection: Array<string> = [];
        var mode_selection: Array<string> = [];
        var tonality_selection: Array<string> = [];

        filter.keys.forEach((key_filter) => {
            if(key_filter.enabled) {
                key_selection.push(key_filter.display);
            }
        });

        filter.modes.forEach((mode_filter) => {
            if(mode_filter.enabled) {
                mode_selection.push(mode_filter.display);
            }
        });
        
        filter.tonality.forEach((tonality_filter) => {
            if(tonality_filter.enabled) {
                tonality_selection.push(tonality_filter.display);
            }
        });

        console.log(tonality_selection);
        var result: Array<string> = [];


        // map all values to each other
        key_selection.forEach((key_signature) => {
            mode_selection.forEach((mode) => {
                // filter tonality based on the mode, e.g. ionian minor isn't a thing
                /*if(mode == "Ionian") {
                    tonality_selection.forEach((tonality) => {
                        if(tonality == "Major") {
                            result.push(key_signature+ " "+ tonality);
                        }
                    });
                } else if(mode == "Aoelian") {
                    tonality_selection.forEach((tonality) => {
                        if(tonality != "Major") {
                            result.push(key_signature+ " "+ tonality);
                        }
                    });
                } else {
                    result.push(key_signature+" "+mode);
                }*/
                result.push(key_signature+" "+mode);
            });

            
            tonality_selection.forEach((tonality) => {
                result.push(key_signature+ " "+ tonality);
            });
        });

        var preset_result = [];

        filter.presets.forEach((preset) => {
            if(preset.enabled) {
                if(preset.name == "lcm_grade1") {
                    preset_result.push("C Major");
                    preset_result.push("F Major");
                    preset_result.push("Bb Major");
                    preset_result.push("B Major");
                }
            }
        });

        result = shuffle(shuffle(result));

        /*var key = key_selection[0];
        var mode = mode_selection[0];
        var tonality: string = tonality_selection[0];

        // only use the tonality when we're in ionian/aoelian
        if((mode !== "ionian") && (mode !== "aoelian")) {
            tonality = "";
        } else {
            mode = "";
        }*/

        /*if(!mode_selection.find(x => x == "ionian")) {
            tonality_selection.find(x => x == "major");
        }*/


      dispatch(generateSuccess(result));
      dispatch(nextSuccess(result.pop()));
    }, 500);
  };
  
  export const next = (remaining: Array<String>, reset: boolean) => (dispatch: Function) => {
      if(reset) {
        remaining = []
      }
    
      dispatch(nextSuccess(remaining.pop()));
  };

  export const update_filter = (filters: Filter) => (dispatch: Function) => {
      dispatch(updateFilterSuccess(filters));
  }

  export const set_hand = (hand: number) => (dispatch: Function) => {
      dispatch(setHandSuccess(hand));
  }