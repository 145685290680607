import * as React from "react";
import { FilterItemComponent } from "./FilterItemComponent";
import { Filter } from "../../store/generator/types";
import "./FilterComponent.css";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

export interface FilterComponentProps {
  filter: Filter;
  handle_update: Function;
}

export class FilterComponent extends React.Component<
FilterComponentProps,
  {}
> {


    handleKeyFilterChange(index: number, value: boolean) {
        this.props.filter.keys[index].enabled = value;
        this.props.handle_update(this.props.filter);
    }

    handleModeFilterChange(index: number, value: boolean) {
        //console.log(index, value);
        this.props.filter.modes[index].enabled = value;
        this.props.handle_update(this.props.filter);
    }

    handleTonalityFilterChange(index: number, value: boolean) {
        this.props.filter.tonality[index].enabled = value;
        this.props.handle_update(this.props.filter);
    }

    handleRandomisationFilterChange(index: number, value: boolean) {
        this.props.filter.randomisation[index].enabled = value;
        this.props.handle_update(this.props.filter);
    }

    handlePresetFilterChange(index: number, value: boolean) {
        this.props.filter.presets[index].enabled = value;
        this.props.handle_update(this.props.filter);
    }

    selectAllKeys(filter: Filter) {
      filter.keys.forEach((k) => {
        k.enabled = true;
      });

      this.props.handle_update(filter);
      this.forceUpdate();
    }

    deselectAllKeys(filter: Filter) {
      filter.keys.forEach((k) => {
        k.enabled = false;
      });

      this.props.handle_update(filter);
    }

    selectAllTonality(filter: Filter) {
      filter.tonality.forEach((k) => {
        k.enabled = true;
      });

      this.props.handle_update(filter);
      this.forceUpdate();
    }

    deselectAllTonality(filter: Filter) {
      filter.tonality.forEach((k) => {
        k.enabled = false;
      });

      this.props.handle_update(filter);
      this.forceUpdate();
    }

    selectAllModes(filter: Filter) {
      filter.modes.forEach((k) => {
        k.enabled = true;
      });

      this.props.handle_update(filter);
      this.forceUpdate();
    }

    deselectAllModes(filter: Filter) {
      filter.modes.forEach((k) => {
        k.enabled = false;
      });

      this.props.handle_update(filter);
      this.forceUpdate();
    }

  render() {
    let key_filters = this.props.filter.keys.map((object, i) => (
        <FilterItemComponent item={object} type="checkbox" key={i} id={i} update={(index: number, value: boolean) => {this.handleKeyFilterChange(index, value)}} />
      ));

    let mode_filters = this.props.filter.modes.map((object, i) => (
        <FilterItemComponent item={object} type="checkbox" key={i} id={i} update={(index: number, value: boolean) => {this.handleModeFilterChange(index, value)}} />
      ));

      let tonality_filters = this.props.filter.tonality.map((object, i) => (
        <FilterItemComponent item={object} type="checkbox" key={i} id={i} update={(index: number, value: boolean) => {this.handleTonalityFilterChange(index, value)}} />
      ));

      /*let randomisation_filters = this.props.filter.randomisation.map((object, i) => (
        <FilterItemComponent item={object} type="radio" key={i} id={i} update={(index: number, value: boolean) => {this.handleRandomisationFilterChange(index, value)}} />
      ));

      let presets = this.props.filter.presets.map((object, i) => (
        <FilterItemComponent item={object} type="checkbox" key={i} id={i} update={(index: number, value: boolean) => {this.handlePresetFilterChange(index, value)}} />
      ));*/
    return (
      <Grid direction={"column"} justify="center"
      alignItems="center"container item xs={12} lg={12}  spacing={1}>
        <Grid item xs={12} lg={6} style={{width: "100%"}}>
          <Card className="filterRoot" elevation={0}>
            <CardContent className="filterContent">
              <Typography gutterBottom variant="h5" component="h2">
                Tonic
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p" align={"center"}>
                {key_filters}
              </Typography>
            </CardContent>
            <CardActions className="filterActions">
              <Button size="small" color="primary" className="example_d" onClick={() => {
                
                this.selectAllKeys(this.props.filter);

            }}>
              Select All
              </Button>
              <Button size="small" color="primary" className="example_d" onClick={() => {
            this.deselectAllKeys(this.props.filter);
          }}>
              Deselect All
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6} style={{width: "100%"}}>
          <Card className="filterRoot" elevation={0}>
            <CardContent className="filterContent">
              <Typography gutterBottom variant="h5" component="h2">
                Tonality
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p" align={"center"}>
                {tonality_filters}
              </Typography>
            </CardContent>
            <CardActions className="filterActions">
              <Button size="small" color="primary" className="example_d" onClick={() => {
                
                this.selectAllTonality(this.props.filter);

            }}>
              Select All
              </Button>
              <Button size="small" color="primary" className="example_d" onClick={() => {
            this.deselectAllTonality(this.props.filter);
          }}>
              Deselect All
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}  style={{width: "100%"}}>
          <Card className="filterRoot" elevation={0}>
            <CardContent className="filterContent">
              <Typography gutterBottom variant="h5" component="h2">
                Mode <span style={{fontSize: "0.5em"}}>(Optional)</span>
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p" align={"center"}>
                {mode_filters}
              </Typography>
              <Typography className="small-text">
              <span className="small-text">Note: Mode scale examples not yet added.</span>
              </Typography>
            </CardContent>
            <CardActions className="filterActions">
              <Button size="small" color="primary" className="example_d" onClick={() => {
                
                this.selectAllModes(this.props.filter);

            }}>
              Select All
              </Button>
              <Button size="small" color="primary" className="example_d" onClick={() => {
            this.deselectAllModes(this.props.filter);
          }}>
              Deselect All
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      
    );
  }
}

/*{/*<div className="form_group  filter-group">
        <div className="settings">
          <div className="settings-title">Tonic</div>
          <div className="settings-filter center-text">{key_filters}</div>
          <button className="filter-group-button" onClick={() => {
            
              this.selectAllKeys(this.props.filter);

          }}>Select all</button> 
          <button onClick={() => {
            this.deselectAllKeys(this.props.filter);
          }}>Deselect all</button>
        </div>
        <div className="settings">
        <div className="settings-title">Tonality</div>
          <div className="settings-filter center-text">{tonality_filters}</div>
          <button onClick={() => {
            
            this.selectAllTonality(this.props.filter);

        }}>Select all</button> 
        <button onClick={() => {
          this.deselectAllTonality(this.props.filter);
        }}>Deselect all</button>
        <br />
        <span className="small-text">Note: Melodic minor scale examples not yet added.</span>
        </div>
        <div className="settings">
        <div className="settings-title">Mode</div>
          <div className="settings-filter center-text">{mode_filters}</div>
          <button onClick={() => {
            
            this.selectAllModes(this.props.filter);

        }}>Select all</button> 
        <button onClick={() => {
          this.deselectAllModes(this.props.filter);
        }}>Deselect all</button>
        <br />
        <span className="small-text">Note: Mode scale examples not yet added.</span>
      </div> */
          /*<h2>Randomisation</h2>
          {randomisation_filters}
          <br />
          <h2>Presets</h2>
          {presets}*/
      /*</div>*/