import * as React from "react";
import "./Piano.css";
import { Typography, Grid, Button } from "@material-ui/core";
import VolumeUp from "@material-ui/icons/VolumeUp";
import * as Tone from "tone";

export interface PianoComponentProps {
  notes: Array<string>;
  fingering: Array<number>;
  hand: number;
  descending: boolean;
}

/**
 * Based on: https://codepen.io/zastrow/pen/oDBki
 */
export class PianoComponent extends React.Component<PianoComponentProps, {}> {
  render() {
    //var display = this.props.key_signature + " " + (this.props.tonality == "" ? this.props.mode : this.props.tonality);
    //console.log("Notes: " + this.props.notes);

    let keys = [
      "key-b key-cb",
      "key-bs key-c",
      "key-cs key-db",
      "key-d key-ez",
      "key-ds key-eb",
      "key-e key-fb",
      "key-es key-f",
      "key-fs key-gb",
      "key-g",
      "key-gs key-ab",
      "key-a key-bz",
      "key-as key-bb",
      "key-b key-cb",
      "key-bs key-c",
      "key-cs key-db",
      "key-d key-ez",
      "key-ds key-eb",
      "key-e key-fb",
      "key-es key-f",
      "key-fs key-gb",
      "key-g",
      "key-gs key-ab",
      "key-a key-bz",
      "key-as key-bb",
      "key-b key-cb",
      "key-bs key-c"
    ];
    const black_keys = [
      "key-cs",
      "key-db",
      "key-ds",
      "key-eb",
      "key-fs",
      "key-gb",
      "key-gs",
      "key-ab",
      "key-as",
      "key-bb"
    ];
    let found_first = false;

    var ht = undefined;
    var notes: any[] = [];
    if (this.props.notes) {
      var octave = 3 + this.props.hand;

      if (this.props.descending) octave++;

      var octave_passed = false;

      var notes_to_play = this.props.notes.map(x => x);

      if (this.props.descending) {
        notes_to_play.reverse();
      }

      console.log(notes_to_play[0].charCodeAt(0));
      // start all scales around the same octave when you play a/b
      if(notes_to_play[0].toUpperCase().charCodeAt(0) < 67) { 
          octave--; 
        }

      notes = notes_to_play.map(note => {
        let s = note.replace("key-", "");
        s =
          s?.charAt(0).toUpperCase() +
          s
            ?.slice(1)
            .replace("s", "#")
            .replace("z", "bb");

        console.log(s.charCodeAt(0));
        if (!this.props.descending) {
          if (s.charCodeAt(0) >= 67) {
            if (octave_passed) {
              octave++;

              console.log("passed octave");
            }
            octave_passed = false;
          } else {
            octave_passed = true;
          }
        } else {
          if (s.charCodeAt(0) < 67) {
            if (octave_passed) {
              octave--;

              console.log("passed octave");
            }
            octave_passed = false;
          } else {
            octave_passed = true;
          }
        }

        return s + octave.toString();
      });

      ht = keys.map((key, i) => {
        var black = false;
        if (this.props.notes.length > 0) {
          if (black_keys.find(x => key.includes(x))) {
            key = "black " + key;
            black = true;
          } else {
            key = "white " + key;
          }

          let comp = this.props.notes[0];
          let re = "\\b" + comp + "\\b";
          console.log(re);
          let match = key.match(re);
          console.log(match);
          if (match != null) {
            found_first = true;
            let s = this.props.notes.shift()!.replace("key-", "");
            s =
              s?.charAt(0).toUpperCase() +
              s
                ?.slice(1)
                .replace("s", "#")
                .replace("z", "bb");

            let finger: string = "";

            if (this.props.fingering !== undefined) {
              finger = this.props.fingering.shift()!.toString();
            }

            if (black)
              return (
                <li className={key} key={i}>
                  <span>
                    {s}
                    <br />
                    {finger}
                  </span>
                </li>
              );
            else
              return (
                <li className={key} key={i}>
                  <span>
                    {finger}
                    <br />
                    {s}
                  </span>
                </li>
              );
          } else {
            if (!found_first) {
              //keys.shift();
            } else {
              return <li className={key} key={i}></li>;
            }
          }
        }

        return;
      });
    }
    return (
      <div>
        <Grid item xs={12}>
            {notes.length > 0 &&
          <Button
            fullWidth={true}
            size={"small"}
            style={{ color: "#fff" }}
            onClick={e => {
              Tone.Transport.start();
              var synth = new Tone.Synth().toMaster();
              /*console.log("in event");
                //var sy = this.synth;
                var seq = new Tone.Sequence(function(time, note){
                  console.log(note);
                  //sy.triggerAttackRelease(note, "8n", time);
                }, ["C4", "E4", "G4", "A4"], "4n");

                seq.start("8n")*/
              //synth.oscillator.type = "sine";
              var seq = new Tone.Sequence(
                function(time, note) {
                  console.log(note);
                  synth.triggerAttackRelease(note, "8n", time);
                },
                notes,
                "4n"
              );
              seq.loop = 0;
              seq.start("+0.1");

              /*for(var i=0; i<notes.length; i++) {
                   synth.triggerAttackRelease(notes[i], '8n', Tone.TransportTime(i))
                }*/
            }}
          >
            <VolumeUp fontSize="small" />
          </Button>
  }
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            {ht && (
              <ul className="set">
                {ht}
                {/*<li className="white b"></li>
                <li className="white c"><span>C</span></li>
                <li className="black cs db"><span></span></li>
                <li className="white d"><span>D</span></li>
                <li className="black ds eb"><span>Eb</span></li>
                <li className="white e"></li>
                <li className="white f"><span>F</span></li>
                <li className="black fs gb"></li>
                <li className="white g"><span>G</span></li>
                <li className="black gs ab"><span>Ab</span></li>
                <li className="white a"></li>
                <li className="black as bb"><span>Bb</span></li>
                <li className="white b"></li>
                <li className="white c"><span>C</span></li>
                <li className="black cs"></li>
                <li className="white d"></li>
                <li className="black ds"></li>
                <li className="white e"></li>
                <li className="white f"></li>
                <li className="black fs"></li>
                <li className="white g"></li>
                <li className="black gs"></li>
                <li className="white a"></li>
                <li className="black as"></li>
                <li className="white b"></li>
    <li className="white c"></li>*/}
              </ul>
            )}
          </Typography>
        </Grid>
      </div>
    );
  }
}
