import { Reducer } from "redux";
import { GenerateState, GenerateActions } from "./types";

const initialState: GenerateState = {
  filter_updated: false,
  key_signature: undefined,
  mode: undefined,
  tonality: undefined,
  isGenerating: false,
  generated: false,
  remaining_scales: [],
  hand: 1,
  filters: {
      presets: [
    {
        name: "lcm_grade1",
        display: "LCM Grade 1",
        enabled: false
    },
    {
        name: "lcm_grade5",
        display: "LCM Grade 5",
        enabled: false
    }
],
    modes: [
      /*{
        name: "ionian",
        display: "Ionian",
        enabled: true
      },*/
      {
        name: "dorian",
        display: "Dorian",
        enabled: false
      },
      {
        name: "phyrigian",
        display: "Phyrigian",
        enabled: false
      },
      {
        name: "lydian",
        display: "Lydian",
        enabled: false
      },
      {
        name: "mixolydian",
        display: "Mixolydian",
        enabled: false
      },
      /*{
        name: "aoelian",
        display: "Aoelian",
        enabled: true
      },*/
      {
        name: "locrian",
        display: "Locrian",
        enabled: false
      }
    ],
    keys: [
      {
        name: "C Natural",
        display: "C",
        enabled: false
      },
      {
        name: "C Sharp",
        display: "C#",
        enabled: false
      },
      {
        name: "D Flat",
        display: "Db",
        enabled: false
      },
      {
        name: "D Natural",
        display: "D",
        enabled: false
      },
      {
        name: "E Flat",
        display: "Eb",
        enabled: false
      },
      {
        name: "E Natural",
        display: "E",
        enabled: false
      },
      {
        name: "F",
        display: "F",
        enabled: false
      },
      {
        name: "F Sharp",
        display: "F#",
        enabled: false
      },
      {
        name: "G Flat",
        display: "Gb",
        enabled: false
      },
      {
        name: "G Natural",
        display: "G",
        enabled: false
      },
      {
        name: "A Flat",
        display: "Ab",
        enabled: false
      },
      {
        name: "A Natural",
        display: "A",
        enabled: false
      },
      {
        name: "B Flat",
        display: "Bb",
        enabled: false
      },
      {
        name: "B Natural",
        display: "B",
        enabled: false
      }
    ],
    tonality: [
      {
        name: "major",
        display: "Major",
        enabled: false
      },
      {
        name: "minor",
        display: "Minor",
        enabled: false
      },
      {
        name: "harmonic_minor",
        display: "Harmonic Minor",
        enabled: false
      },
      {
        name: "melodic_minor",
        display: "Melodic Minor",
        enabled: false
      }
    ],
    allow_enharmonic: false,
    randomisation: [
      {
        name: "random",
        display: "Random",
        enabled: true
      },
      {
        name: "circle_of_fifths",
        display: "Circle of Fifths",
        enabled: false
      },
      {
        name: "neely",
        display: "Neely",
        enabled: false
      }
    ]
  }
};

const reducer: Reducer<GenerateState> = (state = initialState, action) => {
  switch (action.type) {
    case GenerateActions.GENERATE_REQUEST: {
      return {
        ...state,
        isGenerating: true
      };
    }
    case GenerateActions.GENERATE_SUCCESS: {
      return {
        ...state,
        isGenerating: false,
        remaining_scales: action.payload.remaining_scales,
        generated: true
      };
    }
    case GenerateActions.GENERATE_FAILURE: {
      return {
        ...state,
        isGenerating: false,
        key_signature: undefined
      };
    }
    case GenerateActions.NEXT_SUCCESS: {
        return {
            ...state,
            key_signature: action.payload.key_signature
        }
    }
    case GenerateActions.UPDATE_FILTER_SUCCESS: {
        return {
            ...state,
            filters: action.payload.filters,
            filter_updated: !state.filter_updated
        }
    }
    case GenerateActions.SET_HAND_SUCCESS: {
        return {
          ...state,
          hand: action.payload.hand
        }
    }
    default: {
      return state;
    }
  }
};

export { reducer as generateReducer };
